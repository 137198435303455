<template>
  <div class="big_data">
    <header_public :navindex="3"></header_public>
    <div class="b_d_top"></div>
    <div class="b_d_top_text">
      为什么要
      <span>学JAVA大数据</span>
    </div>
    <div class="b_d_first">
      <ul class="b_d_first_title">
        <li>
          <p class="b_d_first_title_num">01</p>
          <p class="b_d_first_title_text">人才缺口大</p>
          <p class="b_d_first_title_des">据统计，2025年前大数据人才需求将保持30%-40%的增速，人才总需求量将达2000万</p>
        </li>
        <li>
          <p class="b_d_first_title_num">02</p>
          <p class="b_d_first_title_text">薪资待遇高</p>
          <p class="b_d_first_title_des">据职友集数据，大数据工程师应届生工资高达18.3K，未来发展钱途无量</p>
        </li>
        <li>
          <p class="b_d_first_title_num">03</p>
          <p class="b_d_first_title_text">产业规模大</p>
          <p class="b_d_first_title_des">据工信部数据，预计到 2025 年底，大数据产业测算规模突破 3 万亿元，入行职业前景广阔</p>
        </li>
        <li>
          <p class="b_d_first_title_num">04</p>
          <p class="b_d_first_title_text">就业选择广</p>
          <p class="b_d_first_title_des">作为互联网开发核心技术，大数据专业就业选择非常广，互联网、政府、银行、医疗等行业都需要大数据人才</p>
        </li>
      </ul>
    </div>
    <div class="b_d_top_btn" @click="kefu()">获取课程大纲</div>
    <div class="b_d_top_text">
      培训费用多少钱
      <span>评估一下</span>
    </div>
    <pinggu></pinggu>
    <div class="b_d_top_text" style="margin-top: 50px;">
      Java大数据
      <span>学什么？</span>
    </div>
    <div class="b_d_top_des">60+专业技能，50+实训项目，学完就能独立做项目</div>

    <div class="b_d_second swiper-container">
      <ul class="b_d_second_list swiper-wrapper">
        <li
          class="b_d_second_list_item swiper-slide"
          v-for="(item,index) in bannerList"
          :key="index"
        >
          <div class="item_head">{{item.step}}</div>
          <div class="item_title">{{item.desc}}</div>
          <div class="item_contentTltle">
            <img
              class="item_contentTltle_img"
              src="../../assets/images/qianduan/shangyejiazhi.png"
              alt
            />
            <span>{{item.contentTltle}}</span>
          </div>
          <div class="item_content">{{item.content}}</div>
          <div class="item_contentTltle">
            <img
              class="item_contentTltle_img"
              src="../../assets/images/qianduan/shangyejiazhi.png"
              alt
            />
            <span>{{item.business}}</span>
          </div>
          <div class="item_content">{{item.businessContent}}</div>
        </li>
      </ul>
      <div class="swiper-pagination"></div>
    </div>
    <div class="b_d_top_btn" @click="kefu()">获取课程大纲</div>
    <div class="b_d_top_text">
      一线大厂丰富实训项目 锻炼<span style="color:#ee3a52;">实战项目</span>能力
      <!-- <span>3年经验</span> -->
    </div>
    <div class="b_d_third">
      <div class="b_d_third_img swiper-container">
        <div class="swiper-wrapper">
          <img class="swiper-slide" src="../../assets/images/qianduan/xiangmu1.png" alt="前端工程师" />
          <img class="swiper-slide" src="../../assets/images/qianduan/xiangmu2.png" alt="前端工程师" />
          <img class="swiper-slide" src="../../assets/images/qianduan/xiangmu3.png" alt="前端工程师" />
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="b_d_top_btn" @click="kefu()">查看报名条件</div>

    <div class="b_d_top_text">
      随经验积累 薪资待遇
      <span>不断翻倍</span>
    </div>
    <div class="b_d_top_des">越老越吃香</div>

    <div class="b_d_fourth">
      <img src="../../assets/images/qianduan/money.png" alt="前端工程师" />
    </div>

    <div class="b_d_top_text"></div>
    <div style="margin-top: 3.125rem;">
      <xinzilist></xinzilist>
    </div>

    <div class="f_e_btn" @click="kefu()">如何高质量就业</div>

    <!-- 		<div class="vis_des_eighth">
			<div>
				<div class="v_d_ei_text">多重就业帮扶 稳定持续高质量就业</div>
				<xieyi></xieyi>
			</div>
		</div>
    <div class="f_e_btn" @click="kefu()">了解就业协议</div>-->
    <div class="vis_des_ninth">
      <div>
        <div class="f_e_environment_text">
          <p>
            积云教育独有“
            <span style="color: #f14549;">大学校园</span>”学习环境
          </p>
        </div>
        <huanjing></huanjing>
      </div>
    </div>
    <div class="f_e_btn" @click="kefu()">预约到校参观</div>
    <div class="vis_des_tenth">
      <div class="f_e_environment_text">
        <p>
          常见问题
          <span style="color: #f14549;">解答</span>
        </p>
      </div>
      <div class="v_d_t_list_text">
        <div>
          <p>
            <span class="text_circle">01</span>对年龄和学历有要求吗？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>
        <div>
          <p>
            <span class="text_circle">02</span>无基础能学吗？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>

        <div>
          <p>
            <span class="text_circle">03</span>学费多少钱？能分期吗？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>
        <div>
          <p>
            <span class="text_circle">04</span>学多长时间？怎么上课？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>

        <div>
          <p>
            <span class="text_circle">05</span>好学吗？学不会怎么办？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>
        <div>
          <p>
            <span class="text_circle">06</span>学校在哪里？住宿是怎么安排？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>

        <div>
          <p>
            <span class="text_circle">07</span>学完能拿到大专学历吗？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>
        <div>
          <p>
            <span class="text_circle">08</span>学完是怎么安排就业的？
          </p>
          <span class="text_answer" @click="kefu()">查看答案</span>
        </div>
      </div>
    </div>

    <bottom_public></bottom_public>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import bottom_public from "../../components/bottom_public/bottom_public";
import header_public from "../../components/header_public/header_public";
import pinggu from '../../components/pinggu/pinggu';
import xinzilist from '../../components/xinzilist/xinzilist';
import huanjing from "../../components/huanjing/huanjing";
import xieyi from "../../components/xieyi/xieyi";

const axios = require("axios").default;

export default {
  metaInfo: {
    title: "大数据开发培训|大数据培训|大数据培训机构-积云教育",
    meta: [{
      name: "description",
      content: "积云教育专注大数据开发培训，根据企业需求潜心研制大数据开发培训课程，为企业输送数以万计的大数据开发实训人才。",
    }, {
      name: "keywords",
      content: "大数据开发培训,大数据,大数据培训,JAVA培训,大数据培训机构",
    },],
  },
  created () { },
  data () {
    return {
      flag: false,
      currentIndex: 0,
      bannerList: [{
        step: "技术阶段1",
        desc: "java入门基础",
        contentTltle: "主要内容",
        content: "Java环境搭建和开发、Java基础语法和入门、IDEA工具使用、面向对象编程基础",
        business: "商业价值",
        businessContent: "已经具备Java语言编程基础，可以实现简单的小系统程序，但达不到企业型人才的标准"
      }, {
        step: "技术阶段2",
        desc: "Java核心进阶",
        contentTltle: "主要内容",
        content: "掌握javaSE课程常用类和常用方法，具备面向对象思想，承上启下，增强java基础知识，为学习JavaWeb完成知识储备",
        business: "商业价值",
        businessContent: "具备JavaSE基本的开发能力，可以实现简单单机应用程序，对企业JavaWeb开发又进一步，也为JavaWeb学习提供基础"
      }, {
        step: "技术阶段3",
        desc: "数据库关键技术",
        contentTltle: "主要内容",
        content: "以JDBC为工具，Mysql数据库为容器，结合tomcat服务器，servlet和Json技术在B/S的环境下完成前后台数据的动态交互",
        business: "商业价值",
        businessContent: "掌握JavaWeb开发基本数据流转流程，熟悉Java基本开发环境、熟悉项目管理工具使用及MySql数据库使用，满足Java软件开发行业的基本开发需求"
      }, {
        step: "技术阶段4",
        desc: "web应用与实战",
        contentTltle: "主要内容",
        content: "1．掌握使用JavaWeb三层架构实现项目的搭建及开发2．掌握文件上传中的图片的上传3．掌握ajax和jquery在前端页面的使用 4．掌握过滤器的基本用法",
        business: "商业价值",
        businessContent: "本阶段学员具备自主独立开发商业项目的能力，可快速搭建后端架构、熟悉前端服务的开发流程、后期项目快速部署"
      }, {
        step: "技术阶段5",
        desc: "互联网核心框架",
        contentTltle: "主要内容",
        content: "Spring高级、SpringMVC高级、握MyBatis框架原理、Spring中IOC，DI，AOP的原理、Spring的注解开发、常见设计模式剖析",
        business: "商业价值",
        businessContent: "掌握框架的使用，对企业项目的构建起着关键性的作用，框架规定了应用体系结构，构成了某类特定软件的可复用设计，可以使开发人员只关注软件的业务功能"
      }, {
        step: "技术阶段6",
        desc: "大数据流行技术",
        contentTltle: "主要内容",
        content: "Maven多模块开发、分布式框架的架构、分布式服务提供者和消费者开发、ElementUI常用组件以及阿里云OSS存储服务使用、Spring Security框架、ECharts的使用",
        business: "商业价值",
        businessContent: "面向服务架构（SOA）是让开发人员更加关注于业务流程而非底层基础结构，从而获得比传统软件开发人员更具竞争力的优势"
      }, {
        step: "技术阶段7",
        desc: "分布式与云存储",
        contentTltle: "主要内容",
        content: "1．掌握消息中间件RabbitMQ2．熟练使用redis缓存3．掌握使用Elasticsearch搜索引擎4．掌握微信开发平台的开发过程",
        business: "商业价值",
        businessContent: "前后端分离开发成为企业中软件架构的新宠儿，而掌握微服务框架的使用、项目环境快速部署的人才已被互联网企业所重视。适应现互联网开发环境，学习微服务框架更具竞争力"
      }]
    }
  },
  methods: {
    enter: function (i) {
      this.flag = true;
      this.currentIndex = i;
    },
    leave: function () {
      this.flag = false;
    },
    kefu () {
      window.mantis.requestChat()
    },
  },
  components: {
    bottom_public,
    header_public,
    pinggu,
    xinzilist,
    huanjing,
    xieyi
  },
  mounted () {
    var swiper = new Swiper('.b_d_second', {
      slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 0,
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination'
      },
    });

    var swiper = new Swiper('.b_d_third_img', {
      slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination'
      },
    });

  },
}
</script>

<style scoped>
@import url("../../assets/css/big_data/big_data.css");
</style>
